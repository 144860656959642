<!--This is the Surgeons page, with the list of all available surgeons. -->
<template>
    <b-container fluid class="list-container py-4">
        <b-row class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start justify-content-between flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary-light mb-0">{{ t('surgeons') }}</h1>
                <b-button
                    class="main-navigation-button mt-3 mt-sm-0"
                    variant="primary"
                    to="NewPatient"
                    v-if="canAddPatient"
                >
                    <b-icon-plus /> {{ t('addNewPatient') }}
                </b-button>
            </b-col>
        </b-row>

        <b-card>
            <b-row class="mb-2">
                <b-col xl="4" lg="12" class="h-80">
                    <h4 class="heavy mb-0">{{ t('surgeonList') }}</h4>
                    <p class="gray-dark mb-0">{{ t('surgeonList_Desc1') }}</p>
                    <p v-if="isDefaultSort" class="gray-dark">
                        {{ t('surgeonList_Desc2') }}
                    </p>
                </b-col>

                <b-col xl="5" lg="8" class="order-1">
                    <b-form @submit.prevent="searchChanged" class="d-flex flex-column flex-sm-row">
                        <b-input-group class="px-0 mr-2 mb-2 mb-xl-0">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input
                                type="search"
                                v-model="queryParams.search"
                                :placeholder="t('surgeonList_SearchPlaceholder')"
                                id="searchInput"
                            ></b-form-input>
                        </b-input-group>

                        <div class="justify-content-end">
                            <b-button type="submit" variant="primary"> {{ t('search') }} </b-button>
                        </div>
                    </b-form>
                </b-col>
                <b-col xl="3" lg="4" class="order-0 order-lg-2 mb-2">
                    <div class="d-flex justify-content-lg-end">
                        <b-button @click="notImplemented()" variant="secondary" class="mr-2">
                            {{ t('filters') }} <b-icon-filter class="ml-1" />
                        </b-button>
                        <b-button @click="notImplemented" variant="secondary">
                            {{ t('export') }} <b-icon-box-arrow-right class="ml-1" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <SurgeonTable :queryParams="queryParams" @sort-changed="sortChanged" />

            <b-row>
                <b-col cols="2" offset="10">
                    <b-pagination
                        v-if="pageDetails.total"
                        @change="pageChange"
                        align="right"
                        size="sm"
                        :value="queryParams.page"
                        :total-rows="pageDetails.total"
                        :per-page="queryParams.perPage"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import merge from 'lodash/merge';
import SurgeonTable from './components/SurgeonTable.vue';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const defaultSort = {
    sortOrder: 'desc',
    sortBy: 'updated',
};
const defaultQueryParams = () => ({
    search: null,
    page: 1,
    perPage: 10,
    ...defaultSort,
});

export default {
    name: 'SurgeonList',
    components: {SurgeonTable},
    data() {
        return {
            queryParams: defaultQueryParams(),
        };
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapState({
            pageDetails: (state) => state.doctors.details,
        }),
        canAddPatient() {
            return this.checkPermissions({
                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
            });
        },
        isDefaultSort() {
            return (
                defaultSort.sortOrder === this.queryParams.sortOrder &&
                defaultSort.sortBy === this.queryParams.sortBy
            );
        },
    },
    methods: {
        ...mapActions('doctors', ['clearList']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        async searchChanged() {
            if (!this.queryParams.search) this.queryParams = defaultQueryParams();
            this.queryParams.page = 1;
            await this.refreshRecords();
        },
        async sortChanged(context) {
            if (!context.sortBy) return;
            this.queryParams.page = 1;
            this.queryParams.sortBy = context.sortBy;
            this.queryParams.sortOrder = context.sortDesc ? 'desc' : 'asc';
            await this.refreshRecords();
        },
        async pageChange(page) {
            this.queryParams.page = page;
            await this.refreshRecords();
        },
        async refreshRecords() {
            this.$router
                .replace({
                    query: this.queryParams,
                })
                .catch(() => {});
            await this.blockingRequest('doctors/fetchList', this.queryParams);
        },
    },
    created() {
        this.clearList();
    },
    async mounted() {
        this.queryParams = merge({}, this.queryParams, this.$route.query);
        await this.refreshRecords();
    },
};
</script>
<style></style>
